import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { clinicianProfilesPropType } from '@constants/propTypes';
import ProfileInfoUpdate from '@components/user/ProfileInfoUpdate';
import ProfileChangePassword from '@components/user/ProfileChangePassword';
import ProfileAddClinic from '@components/user/ProfileAddClinic';
import ProfileChangeInsurance from '@components/user/profile/ProfileChangeInsurance';
import ProfileChangeNotifications from '@components/user/ProfileChangeNotifications';
import UserClinicianProfiles from '@components/clinicians/UserClinicianProfiles';
import Loader from '@components/app/Loader';
import ProfileTabsDropDown from '@/components/user/ProfileTabsDropDown/ProfileTabsDropDown';
import useScreenInfo from '@/hooks/app/useScreenInfo';

function ProfileScreen({ clinicalProfiles }) {
	if (!clinicalProfiles || clinicalProfiles.length === 0) {
		return (
			<div className="medr-layout text-center profile-page">
				<h1>Profile</h1>
				<Loader text="Loading user data" />
			</div>
		);
	}

	const views = [
		'profile',
		'info',
		'password',
		'clinic',
		'insurance',
		'notifications',
	];
	const viewsKey = {
		profile: 'Profile',
		info: 'Update Information',
		password: 'Change password',
		clinic: 'Add new clinic',
		insurance: 'Update insurance agreements',
		notifications: 'Notification Settings',
	};
	const viewOptions = [
		{ value: 'profile', label: 'Profile' },
		{ value: 'info', label: 'Update Information' },
		{ value: 'password', label: 'Change password' },
		{ value: 'clinic', label: 'Add new clinic' },
		{ value: 'insurance', label: 'Update insurance agreements' },
		{ value: 'notifications', labell: 'Notification Settings' },
	];
	const [view, setView] = useState('profile');
	const [title, setTitle] = useState('Profile');
	const colSizes = { sm: 12, md: 3 };

	useEffect(() => {
		const titles = {
			profile: 'Profile',
			info: 'Update Information',
			password: 'Change password',
			clinic: 'Add new clinic',
			insurance: 'Update insurance agreements',
			notifications: 'Update notification settings',
		};
		setTitle(titles[view]);
	}, [view]);

	const [isNarrow] = useScreenInfo(768);

	const buttons = (
		<>
			{views.map((v) => (
				<Row key={v}>
					<Col>
						<Button
							className={`sel-button w100 mb-4 ${view === v ? 'active' : ''}`}
							onClick={() => setView(v)}
						>
							{viewsKey[v]}
						</Button>
					</Col>
				</Row>
			))}
		</>
	);
	const dropdown = (
		<ProfileTabsDropDown
			viewName={title}
			viewOptions={viewOptions}
			updateView={setView}
		/>
	);
	return (
		<div
			className="medr-layout text-center profile-page"
			style={{ marginLeft: 'auto', marginRight: 'auto' }}
		>
			<h1>{title}</h1>

			<Row>
				<Col className="py-1" sm={colSizes.sm} md={colSizes.md}>
					{isNarrow ? dropdown : buttons}
				</Col>
				<Col>
					{view === 'profile' && (
						<UserClinicianProfiles crps={clinicalProfiles} isUser />
					)}
					{view === 'info' && <ProfileInfoUpdate />}
					{view === 'password' && <ProfileChangePassword />}
					{view === 'clinic' && <ProfileAddClinic />}
					{view === 'insurance' && <ProfileChangeInsurance />}
					{view === 'notifications' && <ProfileChangeNotifications />}
				</Col>
			</Row>
		</div>
	);
}

ProfileScreen.propTypes = {
	clinicalProfiles: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	clinicalProfiles: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(ProfileScreen);
