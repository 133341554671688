import React from 'react';
import UserUpdateRequest from './UpdateUserDetails/UserUpdateRequest';
import ReferralsContactsDisplay from './ReferralContacts/ReferralContactsDisplay';
import useReferralContacts from '@hooks/clinicians/useReferralContacts';


function ProfileInfoUpdate() {
    const { 
        referralContacts, 
		requiredEmails,
        updateAppointmentContacts
    } = useReferralContacts();

	return (
		<>
            <UserUpdateRequest />
			<ReferralsContactsDisplay 
				contacts={referralContacts} 
				updateAppointmentContacts={updateAppointmentContacts}
				requiredEmails={requiredEmails}
				/>
		</>
	);
}

export default ProfileInfoUpdate;