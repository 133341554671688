import * as CONSTS from '@constants/referralConstants';
import * as URL from '@constants/urls';
// import * as MSG from '@constants/messageConstants';
import { authGetRequest, authPostRequest, authDeleteRequest } from './axiosActions';
import { getReferralNotifications } from './profileActions';


export const getClinicianReferralsList =
	() => async (dispatch) => {

		const inputData = {
			URL: `${URL.REFERRALS}`,
			GET_REQUEST: CONSTS.CLINICIAN_REFERRALS_REQUEST,
			GET_SUCCESS: CONSTS.CLINICIAN_REFERRALS_SUCCESS,
			GET_FAIL: CONSTS.CLINICIAN_REFERRALS_FAIL,
		};
		dispatch(authGetRequest(inputData));
	};

export const getReferralById =
	(id) => async (dispatch) => {
		const inputData = {
			URL: `${URL.REFERRAL}${id}/`,
			GET_REQUEST: CONSTS.REFERRAL_REQUEST,
			GET_SUCCESS: CONSTS.REFERRAL_SUCCESS,
			GET_FAIL: CONSTS.REFERRAL_FAIL,
		};
		dispatch(authGetRequest(inputData));
	};

export const postCreateReferral =
	(formData, isSuccessfull) =>
	async (dispatch) => {

		const payload = formData;

		const inputData = {
			URL: `${URL.REFERRAL_CREATE}`,
			GET_REQUEST: CONSTS.CREATE_REFERRAL_REQUEST,
			GET_SUCCESS: CONSTS.CREATE_REFERRAL_SUCCESS,
			GET_FAIL: CONSTS.CREATE_REFERRAL_FAIL,
			payload
		};
	
		dispatch(authPostRequest(inputData, isSuccessfull));
	};

export const postReferralToggleAccept = (refId, payload, isSuccessfull) => async (dispatch) => {

		const reqSuccessfull = (value) =>{
			isSuccessfull(value);
			if (!value){
				dispatch(getClinicianReferralsList());
				return;
			}
			dispatch(getReferralNotifications());
		}
		const inputData = {
			URL: `${URL.REFERRAL_TOGGLE_ACCEPT}${refId}/`,
			GET_REQUEST: CONSTS.REFERRAL_TOGGLE_ACCEPT_REQUEST,
			GET_SUCCESS: CONSTS.REFERRAL_TOGGLE_ACCEPT_SUCCESS,
			GET_FAIL: CONSTS.REFERRAL_TOGGLE_ACCEPT_FAIL,
			payload
		};
	
		dispatch(authPostRequest(inputData, reqSuccessfull));
	};

export const postReferralCancel = (refId, isSuccessfull) => async (dispatch) => {
		const inputData = {
			URL: `${URL.REFERRAL_CANCEL}${refId}/`,
			GET_REQUEST: CONSTS.REFERRAL_CANCEL_REQUEST,
			GET_SUCCESS: CONSTS.REFERRAL_CANCEL_SUCCESS,
			GET_FAIL: CONSTS.REFERRAL_CANCEL_FAIL,
		};
	
		dispatch(authDeleteRequest(inputData, isSuccessfull));
	}

export const postReferralToggleComplete = (refId, payload) => async (dispatch) => {
	const isSuccessfull = (value) => {
		if (value){
			dispatch(getClinicianReferralsList());
		}
	};
	
	const inputData = {
		URL: `${URL.REFERRAL_TOGGLE_COMPLETE}${refId}/`,
		GET_REQUEST: CONSTS.REFERRAL_TOGGLE_COMPLETE_REQUEST,
		GET_SUCCESS: CONSTS.REFERRAL_TOGGLE_COMPLETE_SUCCESS,
		GET_FAIL: CONSTS.REFERRAL_TOGGLE_COMPLETE_FAIL,
		payload
	};

	dispatch(authPostRequest(inputData, isSuccessfull));
	};