import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateReferralContacts } from '@actions/profileActions';

const useReferralContacts = () => {
    const dispatch = useDispatch();

    const rxContacts = useSelector((state) => state.profile.userProfile.appointmentsContact);
    const [referralContacts, setReferralContacts] = useState([]);

    // const userEmail = useSelector(state => state.profile.userProfile.user.email);
    const delegates = useSelector(state => state.profile.delegates);
    const delegateEmails = delegates.map(dl => dl.delegateUserProfile.user.email);
    const requiredEmails = [...delegateEmails];

    useEffect(() => {
        if (rxContacts) {
            setReferralContacts(rxContacts);
        } else {
            setReferralContacts([]);
        }
    }, [rxContacts]);
    
    const updateAppointmentContacts = (contacts) => {
        dispatch(updateReferralContacts(contacts));
    }

    return { referralContacts, requiredEmails, updateAppointmentContacts};
}

export default useReferralContacts;