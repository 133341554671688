import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useFilteredClinicians = (jobRoleId) => {
	const lat = useSelector((state) => state.search.lat);
	const lng = useSelector((state) => state.search.lng);
	const initData = {
		jobRoleId: jobRoleId,
		jobRoleSelected: '',
		jobSpecialtySelected: '',
		insuranceProviderId: 0,
		searchLatitude: lat,
		searchLongitude: lng,
	};
	const [filterFields, setFilterFields] = useState(initData);

	const updateFilterFields = (data) => {
		setFilterFields({ ...filterFields, ...data });
	};
	useEffect(() => {
		updateFilterFields({ searchLatitude: lat, searchLongitude: lng });
	}, [lat, lng]);

	return {
		filterFields,
		updateFilterFields,
	};
};

export default useFilteredClinicians;
