import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Modal, Tabs, Tab } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSummaryAnalytics } from '@actions/profileActions';
import { getNameString } from '@actions/general';
import FavouriteClinicianStar from '../app/parts/FavouriteStar/FavouriteClinicianStar';
import { clinicianProfilesPropType } from '@constants/propTypes';

function SeeAllCliniciansModal({
	show,
	handleClose,
	favouriteClinicians,
	topReferralsFrom,
	topReferredTo,
}) {
	return (
		<Modal show={show} onHide={handleClose} size="lg">
			<Modal.Header closeButton>
				<Modal.Title className="medr-text-inv">
					Clinician Interaction Analytics
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Tabs
					defaultActiveKey="favouriteClinicians"
					id="clinicianInteractionTabs"
					className="mb-4 custom-tabs"
				>
					<Tab
						className="custom-tabs"
						eventKey="favouriteClinicians"
						title="Favourite Clinicians"
					>
						<Row className="dashboard-analytics-list-p">
							{favouriteClinicians.map((favClin) => (
								<Col key={`tc${uuidv4()}`} xs={12} md={6}>
									<div className="d-flex align-items-center mb-2">
										<span className="mr-2 font-weight-bold medr-text-inv">
											<FavouriteClinicianStar
												clinician={{
													isFavourite: true,
													userProfile: favClin.favouriteUserProfile,
												}}
											/>
										</span>
										<a
											className="medr-text-inv"
											href={`/clinicians/user/${favClin.favouriteUserProfile.id}`}
										>
											{getNameString(favClin.favouriteUserProfile)}
										</a>
									</div>
								</Col>
							))}
						</Row>
					</Tab>
					<Tab
						className="custom-tabs"
						eventKey="topReferralsFrom"
						title="Top Referrals From"
					>
						<Row className="dashboard-analytics-list-p">
							{topReferralsFrom.map((clinicianInfo, index) => (
								<Col key={`tc${uuidv4()}`} xs={12} md={6}>
									<div className="d-flex align-items-center mb-2">
										<span className="mr-2 font-weight-bold medr-text-inv">
											{index + 1}.
										</span>
										<a
											className="medr-text-inv"
											href={`/clinicians/user/${clinicianInfo.clinician.id}`}
										>
											{getNameString(clinicianInfo.clinician)} (
											{clinicianInfo.count})
										</a>
									</div>
								</Col>
							))}
						</Row>
					</Tab>
					<Tab
						className="custom-tabs"
						eventKey="topReferredTo"
						title="Top Referred To"
					>
						<Row className="dashboard-analytics-list-p">
							{topReferredTo.map((clinicianInfo, index) => (
								<Col key={`tc${uuidv4()}`} xs={12} md={6}>
									<div className="d-flex align-items-center mb-2">
										<span className="mr-2 font-weight-bold medr-text-inv">
											{index + 1}.
										</span>
										<a
											className="medr-text-inv"
											href={`/clinicians/user/${clinicianInfo.clinician.id}`}
										>
											{getNameString(clinicianInfo.clinician)} (
											{clinicianInfo.count})
										</a>
									</div>
								</Col>
							))}
						</Row>
					</Tab>
				</Tabs>
			</Modal.Body>
		</Modal>
	);
}

SeeAllCliniciansModal.propTypes = {
	show: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	favouriteClinicians: clinicianProfilesPropType.isRequired,
	topReferralsFrom: clinicianProfilesPropType.isRequired,
	topReferredTo: clinicianProfilesPropType.isRequired,
};

function UserAnalyticsSummary() {
	const lg = 6;
	const md = 6;
	const sm = 12;

	const [showSeeAllCliniciansModal, setShowSeeAllCliniciansModal] =
		useState(false);

	const dispatch = useDispatch();
	const refreshAnalytics = () => dispatch(getUserSummaryAnalytics(5));
	useEffect(() => {
		refreshAnalytics();
	}, [dispatch]);

	const userProfile = useSelector((state) => state.profile.userProfile);
	const analytics = useSelector((state) => state.profile.analytics);

	if (!userProfile) {
		return <div id="user_analytics_missing_up">Loading...</div>;
	}

	if (!analytics) {
		return <div id="user_analytics_missing_up">Loading...</div>;
	}

	const {
		number,
		noPatientsMade,
		noReferralsMade,
		noReferralsReceived,
		topReferredTo,
		topReferralsFrom,
	} = analytics;

	const { favouriteClinicians } = analytics;

	function handleValue(value) {
		if (value === undefined || value === null) {
			return 'NaN';
		}
		return value;
	}

	const statsCard = (
		<Card
			className="my-3 py-3 rounded d-flex flex-fill medr-card"
			id="analyticsStatsCard"
		>
			<Card.Title>Platform Statistics</Card.Title>
			<Card.Body className="d-flex flex-column text-left">
				<ul className="dashboard-analytics-list-p">
					<li key={uuidv4()}>
						<span className="dashboard-analytics-sp">Patients Added:</span>
						<span>{handleValue(noPatientsMade)}</span>
					</li>
					<li key={uuidv4()}>
						<span className="dashboard-analytics-sp">Referrals Sent:</span>
						<span>{handleValue(noReferralsMade)}</span>
					</li>
					<li key={uuidv4()}>
						<span className="dashboard-analytics-sp">Referrals Received:</span>
						<span>{handleValue(noReferralsReceived)}</span>
					</li>
				</ul>
				<div className="mt-auto"> </div>
			</Card.Body>
		</Card>
	);

	function topReferrersCard(referrers, title, keyId) {
		if (
			referrers === undefined ||
			referrers === null ||
			Object.keys(referrers).length === 0
		) {
			return (
				<Card
					className="my-3 p-3 rounded d-flex flex-fill medr-card"
					id={keyId}
				>
					<Card.Title>{title}</Card.Title>
					<Card.Body className="d-flex flex-column text-left">
						<p className="dashoard-ana-text text-center">
							Top referrers data not available
						</p>
					</Card.Body>
				</Card>
			);
		}
		const referrersLim = 5;

		return (
			<Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id={keyId}>
				<Card.Title>{title}</Card.Title>
				<Card.Body className="d-flex flex-column text-left">
					<ol className="dashboard-analytics-list-p">
						{referrers.slice(0, referrersLim).map((clinicianInfo) => (
							<li key={`tc${uuidv4()}`}>
								<div className="row" key={uuidv4()}>
									<div className="col-1">
										<FavouriteClinicianStar
											clinician={{
												isFavourite: clinicianInfo.isFavourite,
												userProfile: clinicianInfo.clinician,
											}}
											requestMade={refreshAnalytics}
										/>
									</div>
									<div className="col clinician-name">
										<a
											className="medr-text-inv"
											href={`/clinicians/user/${clinicianInfo.clinician.id}`}
										>
											{getNameString(clinicianInfo.clinician)} (
											{clinicianInfo.count})
										</a>
									</div>
								</div>
							</li>
						))}
					</ol>
					{referrers.length > referrersLim && (
						<p>
							<button
								type="button"
								onKeyDown={() => setShowSeeAllCliniciansModal(true)}
								onClick={() => setShowSeeAllCliniciansModal(true)}
								className="btn-link see-all-clinicians"
							>
								See all clinicians
							</button>
						</p>
					)}
					<div className="mt-auto" />
				</Card.Body>
			</Card>
		);
	}

	function getFavouriteClinicinasCard() {
		if (
			favouriteClinicians === undefined ||
			favouriteClinicians === null ||
			favouriteClinicians.length === 0 ||
			Object.keys(favouriteClinicians).length === 0
		) {
			return (
				<Card
					className="my-3 p-3 rounded d-flex flex-fill medr-card"
					id="favouriteCliniciansCard"
				>
					<Card.Title>Favourite Clinicians</Card.Title>
					<Card.Body className="d-flex flex-column text-left">
						<p className="text-center">
							Favourite clinicians have not been selected
						</p>
						<div className="mt-auto" />
					</Card.Body>
				</Card>
			);
		}
		const favCliniciansLim = 3;

		return (
			<Card
				className="my-3 p-3 rounded d-flex flex-fill medr-card"
				id="favouriteCliniciansCard"
			>
				<Card.Title>Favourite Clinicians</Card.Title>
				<Card.Body className="d-flex flex-column text-left">
					<ul style={{ listStyleType: 'none', padding: '0px' }}>
						{favouriteClinicians.slice(0, favCliniciansLim).map((favClin) => (
							<li key={uuidv4()}>
								<div className="row" key={uuidv4()}>
									<div className="col-1">
										<FavouriteClinicianStar
											clinician={{
												isFavourite: true,
												userProfile: favClin.favouriteUserProfile,
											}}
											requestMade={refreshAnalytics}
										/>
									</div>
									<div className="col clinician-name">
										<a
											className="medr-text-inv"
											href={`/clinicians/user/${favClin.favouriteUserProfile.id}`}
										>
											{getNameString(favClin.favouriteUserProfile)}
										</a>
									</div>
								</div>
							</li>
						))}
					</ul>
					{favouriteClinicians.length > favCliniciansLim && (
						<p>
							<button
								type="button"
								onKeyDown={() => setShowSeeAllCliniciansModal(true)}
								onClick={() => setShowSeeAllCliniciansModal(true)}
								className="btn-link see-all-clinicians"
							>
								See all clinicians
							</button>
						</p>
					)}
					<div className="mt-auto" />
				</Card.Body>
			</Card>
		);
	}

	return (
		<div
			className="medr-layout text-center"
			style={{ marginLeft: 'auto', marginRight: 'auto' }}
		>
			<SeeAllCliniciansModal
				show={showSeeAllCliniciansModal}
				handleClose={() => setShowSeeAllCliniciansModal(false)}
				favouriteClinicians={favouriteClinicians}
				topReferralsFrom={topReferralsFrom}
				topReferredTo={topReferredTo}
			/>
			<Row>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{statsCard}
				</Col>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{getFavouriteClinicinasCard()}
				</Col>
			</Row>
			<Row>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{topReferrersCard(
						topReferralsFrom,
						`Top ${number} Clinicians Referrals Received`,
						'topReferrersFromCard'
					)}
				</Col>
				<Col className="medr-card-col" lg={lg} md={md} sm={sm}>
					{topReferrersCard(
						topReferredTo,
						`Top ${number} Clinicians Referrals Sent`,
						'topReferrersToCard'
					)}
				</Col>
			</Row>
		</div>
	);
}

export default UserAnalyticsSummary;
