import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { userProfilePropType } from '@constants/propTypes';
import TitleDropdown from "../TitleDropdown/TitleDropdown";
// import { EmailFormField } from "../../app/parts/FormFields";
import { updateProfile } from '@actions/profileActions';
import useUserUpdateRequest from './useUserUpdateRequest';
import ClinicianDescriptionField from '@components/user/UpdateUserDetails/ClinicianDescriptionField';

function UserUpdateRequest({ userProfile }) {
    const {
        userFormData,
        updateFormData,
        setDOB, 
        isDOBValid
    } = useUserUpdateRequest(userProfile);

    const { title, firstName, lastName, dob, clinicianDescription } = userFormData;
    
    const dispatch = useDispatch();
    const onSubmit = () => dispatch(updateProfile(userFormData));

	return (
        <Form className='text-left'>
            <div className="row">
                <div className="col-md-4 py-1">
                    <Form.Label className='my-0 mt-3 text-left'>Title</Form.Label>
                    <TitleDropdown title={title} sendTitle={updateFormData} />
                </div>
                <div className="col-md-8 py-1">
                    <Form.Group controlId="firstName">
                        <Form.Label className='my-0 mt-3 text-left'>First name</Form.Label>
                        <Form.Control
                            required
                            type="name"
                            placeholder="Enter first name"
                            value={firstName}
                            className='mb-3'
                            onChange={(e) => updateFormData({firstName: e.target.value})}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 py-1">
                    <Form.Group controlId="dob" className="mb-3">
                        <Form.Label className='my-0'>DOB</Form.Label>
                        <Form.Control
                            required
                            type="date"
                            name="dob"
                            value={dob}
                            onChange={(e) => setDOB(e)}
                            isInvalid={!isDOBValid}
                            />
                            <Form.Control.Feedback type="invalid">
                                Check patient is over 18 years old.
                            </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className="col-md-8 py-1">
                    <Form.Group controlId="lastName">
                        <Form.Label className='my-0'>Last name</Form.Label>
                        <Form.Control
                            required
                            type="name"
                            placeholder="Enter last name"
                            value={lastName}
                            className='mb-3'
                            onChange={(e) => updateFormData({lastName: e.target.value})}
                        />
                    </Form.Group>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    <ClinicianDescriptionField 
                        clinicianDescription={clinicianDescription}
                        updateFormData={updateFormData}
                        />
                </div>
            </div>
            <Row className='mt-4 mb-5'>
                <Col className='text-center'>
                    <Button className="sel-button w80" variant="primary" onClick={onSubmit}>Update user information</Button>
                </Col>
            </Row>
        </Form>
	);
}

UserUpdateRequest.propTypes = {
	userProfile: userProfilePropType.isRequired
}

const mapStateToProps = (state) => ({
	userProfile: state.profile.userProfile,
});

export default connect(mapStateToProps, {})(UserUpdateRequest);