import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Card } from 'react-bootstrap';
import { userProfilePropType } from '@constants/propTypes';
import { getNameString } from '@actions/general';
import useDelegateUserCard from './useDelegateUserCard';

export function DeactivatedDelegateUserCard({ delegate }) {
	if (!delegate || delegate === undefined) return;

	const handleClick = () => {
		const subject = 'Request to Restore User Account';
		const body = `Dear MedR Registration Team,

I would like to request the restoration of my delegate user account.

Email: ${delegate.delegateUserProfile.user.email}

Thank you for your assistance.

Best regards,
${getNameString(delegate.clinicianUserProfile)}
${delegate.clinicianUserProfile.user.email}
        `;

		const mailtoLink = `mailto:info@medr.co.uk?subject=${encodeURIComponent(
			subject
		)}&body=${encodeURIComponent(body)}`;
		window.location.href = mailtoLink;
	};

	return (
		<Card
			className="mb-2 rounded d-flex flex-fill"
			style={{ padding: '0.5rem' }}
		>
			<div className="d-flex justify-content-between">
				<div className="text-start">
					<Card.Title
						style={{
							marginBottom: '0rem',
							marginTop: '0rem',
							textAlign: 'left',
						}}
					>
						{getNameString(delegate.delegateUserProfile)}
					</Card.Title>
					<Card.Subtitle
						className="text-muted"
						style={{
							marginTop: '0rem',
							marginBottom: '0rem',
							textAlign: 'left',
						}}
					>
						{delegate.delegateUserProfile.user.email}
					</Card.Subtitle>
				</div>
				<Button
					style={{
						borderRadius: '20px',
						padding: '10px 10px',
						fontSize: '0.7rem',
					}}
					variant="info"
					disabled={!delegate.hasRegistered}
					onClick={handleClick}
				>
					{delegate.hasRegistered
						? 'Restore user'
						: 'Awaiting user registration'}
				</Button>
			</div>
		</Card>
	);
}

DeactivatedDelegateUserCard.propTypes = {
	delegate: userProfilePropType.isRequired,
};

function PermissionToggleBar({ field, label, value = false, onChange }) {
	const sendNewValue = (newValue) => onChange({ [field]: newValue });
	return (
		<div className="notif-setting-bar">
			<div className="notif-setting-left">
				<Button
					variant={value ? 'success' : 'outline-success'}
					className="medr-text notif-button"
					onClick={() => sendNewValue(true)}
				>
					Enabled
				</Button>
				<Button
					variant={!value ? 'danger' : 'outline-danger'}
					className="medr-text notif-button"
					onClick={() => sendNewValue(false)}
				>
					Disabled
				</Button>
			</div>
			<div className="notif-setting-right medr-text medr-small">{label}</div>
		</div>
	);
}

PermissionToggleBar.propTypes = {
	field: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

function DeactivateDelegateModal({
	showModal = false,
	handleCloseModal = () => {},
	deactivateDelegate = () => {},
}) {
	return (
		<Modal show={showModal} onHide={handleCloseModal}>
			<Modal.Header closeButton>
				<Modal.Title className="medr-text-inv">
					Confirm Deactivation
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Are you sure you want to deactivate this delegate account?
			</Modal.Body>
			<Modal.Footer>
				<Button variant="info" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button
					variant="danger"
					onClick={() => {
						deactivateDelegate();
					}}
				>
					Deactivate delegate
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
DeactivateDelegateModal.propTypes = {
	showModal: PropTypes.bool,
	handleCloseModal: PropTypes.func,
	deactivateDelegate: PropTypes.func,
};

function DelegateUserCard({ delegate }) {
	const {
		deactivateDelegate,
		updatePermission,
		showDectivateModal,
		toggleShowDeactivate,
	} = useDelegateUserCard(delegate);

	return (
		<>
			<DeactivateDelegateModal
				showModal={showDectivateModal}
				handleCloseModal={toggleShowDeactivate}
				deactivateDelegate={deactivateDelegate}
			/>
			<Card
				className="mb-2 rounded d-flex flex-fill"
				style={{ padding: '0.5rem' }}
			>
				<div className="d-flex justify-content-between">
					<div className="text-start">
						<Card.Title
							style={{
								marginBottom: '0rem',
								marginTop: '0rem',
								textAlign: 'left',
							}}
						>
							{getNameString(delegate.delegateUserProfile)}
						</Card.Title>
						<Card.Subtitle
							className="text-muted"
							style={{
								marginTop: '0rem',
								marginBottom: '0rem',
								textAlign: 'left',
							}}
						>
							{delegate.delegateUserProfile.user.email}
						</Card.Subtitle>
					</div>
					<Button
						style={{
							borderRadius: '20px',
							padding: '10px 10px',
							fontSize: '0.7rem',
						}}
						variant="danger"
						onClick={toggleShowDeactivate}
					>
						Deactive delegate account
					</Button>
				</div>
				<PermissionToggleBar
					field="permissionViewReferrals"
					label="Able to view referrals"
					value={delegate.permissionViewReferrals}
					onChange={updatePermission}
				/>
				<PermissionToggleBar
					field="permisisonCreateReferrals"
					label="Able to create and send referrals"
					value={delegate.permisisonCreateReferrals}
					onChange={updatePermission}
				/>
				<PermissionToggleBar
					field="permisisonManageReferrals"
					label="Able to manage referrals (accept and reject)"
					value={delegate.permisisonManageReferrals}
					onChange={updatePermission}
				/>
			</Card>
		</>
	);
}

DelegateUserCard.propTypes = {
	delegate: userProfilePropType.isRequired,
};

export default DelegateUserCard;

{
	/* <div className='medr-rounded bg-medr-gray bluegreen mb-4 text-left'>

<Row>
    <Col className="text-left">
        <h4 className="bluegreen">{getNameString(delegate.delegateUserProfile)}</h4>
    </Col>
    <Col className="text-left">
        <p className="bluegreen">{delegate.delegateUserProfile.user.email}</p>
    </Col>
</Row>
<PermissionToggleBar 
    field='permissionViewReferrals' 
    label='Able to view referrals' 
    value={delegate.permissionViewReferrals} 
    onChange={updatePermission} // es-lint-disable-line no-console
    />
<PermissionToggleBar 
    field='permisisonCreateReferrals' 
    label='Able to create and send referrals' 
    value={delegate.permisisonCreateReferrals} 
    onChange={updatePermission} // es-lint-disable-line no-console
    />
<PermissionToggleBar 
    field='permisisonManageReferrals' 
    label='Able to manage referrals (accept and reject)' 
    value={delegate.permisisonManageReferrals} 
    onChange={updatePermission} // es-lint-disable-line no-console
    />
<Row>
    <Col className="text-center">
        <Button variant='danger' onClick={toggleShowDeactivate}>Deactive delegate account</Button>
    </Col>
</Row>
</div> */
}
