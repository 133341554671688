import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { patientsPropType } from '@constants/propTypes';
// import PatientCard from './PatientCard';
import PatientBar from './PatientBar';
import PaginationBar from '../app/PaginationBar';
import usePatientsList from '@hooks/patients/usePatientsList';
import PatientFilterInput from './PatientFilterInput';
import usePatientFilter from '@hooks/patients/usePatientFilter';

function NoPatientsDisplay() {
    return (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No patients to display at the moment</h4>
            </div>
        </div>
    )
}



function PatientCardsDisplay({ patientsList }) {
    const nPatientsWindow = 25;
    const start = 0;
    const end = nPatientsWindow;

    const [
        filterStr,
        setFilterStr
    ] = usePatientFilter()
    const [
        patients,
        updatePatientsSlice,
        patientsLength
    ] = usePatientsList(patientsList, start, end, filterStr);


    if (!patientsList || patientsList.length === 0) {
        return <NoPatientsDisplay />
    }

    return (
        <>
            {patientsLength === 0 && noPatientsDisplay}
            {patientsLength > 0 && (
                <Row className='text-left p-1'>
                    <Col className="px-filter-bar-col">
                        <PatientFilterInput setFilterString={setFilterStr} />
                    </Col>
                </Row>
            )}
            {patients.map((patient) => (
                <div className='p-1' key={`pxD${uuidv4()}`}>
                    <PatientBar patient={patient} />
                </div> 
            ))}
            {patientsLength > nPatientsWindow && (
                <>
                    <hr className='medr'/>
                    <PaginationBar arrayLength={patientsLength} setSliceValues={updatePatientsSlice} windowSize={nPatientsWindow}/>
                </>
            )}
        </>
    );

}

PatientCardsDisplay.propTypes = {
	patientsList: patientsPropType.isRequired,
};

export default PatientCardsDisplay;
