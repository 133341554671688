import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { referralsPropType } from '@constants/propTypes';
// import ReferralCard from './ReferralCard';
import PaginationBar from '../../app/PaginationBar';
import ReferralBar from '../ReferralBar';
import useFilterReferralsList from '@hooks/referrals/useFilterReferralsList';

function ReferralAcceptedCardsDisplay({ referralsList }) {
    const nReferralsWindow = 25;
    const start = 0;
    const end = nReferralsWindow;
    const [refAccepted, setRefAccepted] = useState([]);
    const [refCompleted, setRefCompleted] = useState([]);
    const [seeCompleted, setSeeCompleted] = useState(false);

    useEffect(() => {
        if (referralsList === null || referralsList === undefined) {
            return;
        }
        const refAcc = referralsList.filter(referral => referral.completed === false);
        const refComp = referralsList.filter(referral => referral.completed === true);
        const sortedAccepted = refAcc; // .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
        const sortedcompleteed = refComp; // .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
        setRefAccepted(sortedAccepted);
        setRefCompleted(sortedcompleteed);
    }, [referralsList]);

    const [
        referralsAccepted, 
        updateReferralsAcceptedSlice,
        referralsAcceptedLength
    ] = useFilterReferralsList(refAccepted, start, end);
    const [
        referralsCompleted, 
        updateReferralsCompletedSlice,
        referralsCompletedLength
    ] = useFilterReferralsList(refCompleted, start, end);
    
    const headers = (
        <div className="py-1" style={{marginLeft:'12px'}}>
            <div className="align-items-center p-0 referral-bar referral-bar-header d-none d-md-flex">
                <div className='flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-1'>
                    <h4 className='m-0'>Patient</h4>
                </div>
                <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2'>
                    <h4 className='m-0'>DOB</h4>
                </div>
                <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-3'>
                    <h4 className='m-0'>Sex</h4>
                </div>
                <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-4'>
                    <h4 className='m-0'>Clinician</h4>
                </div>
                <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-5'>
                    <h4 className='m-0'>&nbsp;</h4>
                </div>
            </div>
        </div>
    )

    const noReferralsDisplay = (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No referrals to display at the moment</h4>
            </div>
        </div>
    )

    return (
        <>
            {referralsCompletedLength === 0 && referralsAcceptedLength === 0 && noReferralsDisplay}

            {referralsAccepted.length > 0 && headers}
            {referralsAccepted.map((referral) => (               
                <div className='p-1' key={uuidv4()}>
                    <ReferralBar  key={uuidv4()}referral={referral} />
                </div> 
                ))}
            {referralsAcceptedLength > nReferralsWindow && (
                <>
                    <hr className='medr' />
                    <PaginationBar arrayLength={referralsAcceptedLength} setSliceValues={updateReferralsAcceptedSlice} windowSize={nReferralsWindow}/>
                </>
            )}
            {referralsCompletedLength > 0 && (
                <Row className='text-center mt-4'>
                    <Col>
                        <Button  className="sel-button w50" variant={seeCompleted ? 'info' : 'primary'} onClick={() => setSeeCompleted((prev) => !prev)}>
                            {seeCompleted ? "Hide" : "See"} completed referrals
                        </Button>
                    </Col>
                </Row>
            )}
            {seeCompleted && referralsCompleted.length > 0 && headers}
            {seeCompleted && referralsCompleted.map((referral) => (               
                <div className='p-1' key={uuidv4()}>
                    <ReferralBar key={uuidv4()} referral={referral} />
                </div> 
                ))}
            {seeCompleted && referralsCompletedLength > nReferralsWindow && (
                <>
                    <hr className='medr' />
                    <PaginationBar arrayLength={referralsCompletedLength} setSliceValues={updateReferralsCompletedSlice} windowSize={nReferralsWindow}/>
                </>
            )}
        </>
    );
}

ReferralAcceptedCardsDisplay.propTypes = {
	referralsList: referralsPropType.isRequired,
};


export default ReferralAcceptedCardsDisplay;