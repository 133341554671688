import { useState } from 'react';

const useUserUpdateRequest = (userProfile) => {
    const initData = {
        title: userProfile.title,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        // email: userProfile.user.email,
        dob: userProfile.dob,
        clinicianDescription: userProfile.clinicianDescription,
    }
    const [userFormData, setUserFormData] = useState(initData);
    const [isDOBValid, setIsDOBValid] = useState(true);
    const updateFormData = (data) => setUserFormData({ ...userFormData, ...data });

    const checkAge = (DOB) => {
		if (!DOB) {
			setIsDOBValid(false); // If no date is selected, consider it not old enough.
		  return;
		}
	
		// Parse the birthDate string to a Date object
		const birthDateObject = new Date(DOB);
		const currentDate = new Date();
	
		// Calculate the difference in years
		const ageDifference = currentDate.getFullYear() - birthDateObject.getFullYear();
	
		// Check if the person is 18 or more years old
		if (ageDifference >= 18 && ageDifference <= 100){
			setIsDOBValid(true);
		} else {
			setIsDOBValid(false);
		}
	  };

	const setDOB = (e) => {
		checkAge(e.target.value);
		setUserFormData({ ...userFormData, dob: e.target.value });
	}
    return {
        userFormData,
        updateFormData,
        setDOB,
        isDOBValid
    }
};

export default useUserUpdateRequest;