import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ClinicianCard from './ClinicianCard';
import {
	clinicanProfileRanksPropType,
	funcPropType,
} from '@constants/propTypes';

function ClinicianPanel({
	clinicians,
	hoveredClinicianUserProfileIds,
	updateHovered,
	onClinicianSelect,
	refreshClinicians,
	showReferralButton = false,
}) {
	if (!clinicians || clinicians.length < 1) {
		return (
			<Card
				className="mb-2 rounded d-flex flex-fill"
				style={{ padding: '1rem' }}
			>
				<Card.Title>No Clinicians Available</Card.Title>
				<Card.Subtitle className="text-muted">
					Unfortunately, we couldn't find any clinicians that fit your search.
					Please modify your search criteria and try again.
				</Card.Subtitle>
			</Card>
		);
	}

	const cards =
		clinicians &&
		clinicians.map((clinician) => (
			<ClinicianCard
				key={clinician.id}
				clinician={clinician}
				hoveredClinicianUserProfileIds={hoveredClinicianUserProfileIds}
				updateHovered={updateHovered}
				onClinicianSelect={onClinicianSelect}
				refreshClinicians={refreshClinicians}
				showReferralButton={showReferralButton}
			/>
		));

	return <div>{cards}</div>;
}

ClinicianPanel.propTypes = {
	clinicians: clinicanProfileRanksPropType.isRequired,
	hoveredClinicianUserProfileIds: PropTypes.arrayOf(PropTypes.number)
		.isRequired,
	updateHovered: funcPropType.isRequired,
	onClinicianSelect: funcPropType.isRequired,
	refreshClinicians: funcPropType.isRequired,
	showReferralButton: PropTypes.bool,
};

export default ClinicianPanel;
